// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/wout/Documents/WORK/ELKE/kinepluskids/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/wout/Documents/WORK/ELKE/kinepluskids/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aanbod-js": () => import("/Users/wout/Documents/WORK/ELKE/kinepluskids/src/pages/aanbod.js" /* webpackChunkName: "component---src-pages-aanbod-js" */),
  "component---src-pages-contact-js": () => import("/Users/wout/Documents/WORK/ELKE/kinepluskids/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/wout/Documents/WORK/ELKE/kinepluskids/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-werkwijze-js": () => import("/Users/wout/Documents/WORK/ELKE/kinepluskids/src/pages/werkwijze.js" /* webpackChunkName: "component---src-pages-werkwijze-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/wout/Documents/WORK/ELKE/kinepluskids/.cache/data.json")

